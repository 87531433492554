import React from 'react'

import { Image } from 'react-bulma-components';

export const IPhoneX = ({image, landscape=false}) => (
    <div className={`marvel-device iphone-x ${landscape ? "landscape" : ""}`}>
        <div className="notch">
            <div className="camera"></div>
            <div className="speaker"></div>
        </div>
        <div className="top-bar"></div>
        <div className="sleep"></div>
        <div className="bottom-bar"></div>
        <div className="volume"></div>
        <div className="overflow">
            <div className="shadow shadow--tr"></div>
            <div className="shadow shadow--tl"></div>
            <div className="shadow shadow--br"></div>
            <div className="shadow shadow--bl"></div>
        </div>
        <div className="inner-shadow"></div>
        <div className="screen">
            <Image src={image} />
        </div>
    </div>
);

export const IPhone5s = ({image, size="large", color="gold", landscape=false}) => (
    <div className={`marvel-device iphone5s ${size} ${color} ${landscape ? "landscape" : ""}`}>
        <div className="top-bar"></div>
        <div className="sleep"></div>
        <div className="volume"></div>
        <div className="camera"></div>
        <div className="sensor"></div>
        <div className="speaker"></div>
        <div className="screen">
            <Image src={image} />
        </div>
        <div className="home"></div>
        <div className="bottom-bar"></div>
    </div>
)