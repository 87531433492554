import React from "react";
import { Form } from 'react-bulma-components';

export class ContactForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      status: "",
      errorMessage: "",
      forceStatus: false,
      action: props.action ?? "https://formspree.io/xdogydwp",
      wrapFields: props.wrapFields ?? false
    };

    this.submitForm = this.submitForm.bind(this);
  }

  resetForceStatus() {
    this.setState({forceStatus: false});
  }

  render() {
    const { status } = this.state;
    return (
      <form
        onSubmit={this.submitForm}
        action={this.state.action}
        method="POST"
      >
        {
          this.props.wrapFields
            ?
            <Form.Field>
              {this.props.fields}
              {this.props.submit}
            </Form.Field>
            :
            this.props.fields

        }
        {(!this.props.emptyStatus || this.state.forceStatus) && status === "SUCCESS" ? this.props.success : (this.props.wrapFields ? undefined : this.props.submit)}
        {(!this.props.emptyStatus || this.state.forceStatus) && status === "ERROR" && this.props.error}
      </form>
    );
  }

  submitForm(ev) {
    this.setState({status: "", forceStatus: false});

    if (this.props.submitCallback) {
        this.props.submitCallback();
    }

    console.log(ev.target)
    if (this.props.isInvalid()) {
        this.setState({status: "ERROR", forceStatus: true});
        ev.preventDefault();
    } else {
        ev.preventDefault();
        const form = ev.target;
        const data = new FormData(form);
        console.log(data)
        const xhr = new XMLHttpRequest();
        xhr.open(form.method, form.action);
        xhr.setRequestHeader("Accept", "application/json");
        xhr.onreadystatechange = () => {
          if (xhr.readyState !== XMLHttpRequest.DONE) return;
          if (xhr.status === 200) {
            form.reset();
            this.setState({ status: "SUCCESS" });
          } else {
            this.setState({ status: "ERROR" });
          }
        };
        xhr.send(data);
    }
  }
}
