import React, { Fragment, useRef, useState } from "react";

import { Container, Heading, Columns, Form, Icon, Button, Message } from 'react-bulma-components';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPaperPlane, faEnvelope, faUser } from '@fortawesome/free-solid-svg-icons'

import { ContactForm } from './contactForm'
import { StyledSection } from './styledSection'

export const Contact = () => {
  const [state, setState] = useState({
    Message: '',
    Name: '',
    Email: '',
    messageClosed: false,
    invalidMessage: ""
  });

  const child = useRef(null)

  const onChange = (evt) => {
    const value = evt.target.type === 'checkbox' ? evt.target.checked : evt.target.value;
    setState({...state, [evt.target.name]: value});
  }

  const onClose = () => {
    setState({...state, messageClosed: true, invalidMessage: ""});
    child.current.resetForceStatus();
  }

  return (
    <StyledSection id="contact">
      <Container>
        <Columns
          multiline>
          <Columns.Column
            className="about-me"
            size={12}>
            <Heading className="section-title has-text-centered">
              Contact Us
            </Heading>
          </Columns.Column>
          <Columns.Column
            className="about-me"
            size={8}
            offset={2}>
              <ContactForm
                ref={child}
                emptyStatus={state.messageClosed}
                submitCallback={() => setState({...state, messageClosed: false})}
                isInvalid={() => {
                  if (!state.Name){
                    setState({...state, invalidMessage: "Name cannot be empty"})
                    return true
                  }

                  if (!state.Email){
                    setState({...state, invalidMessage: "Email cannot be empty"})
                    return true
                  }

                  if (!state.Message){
                    setState({...state, invalidMessage: "Message cannot be empty"})
                    return true
                  }

                  return false
                }}
                fields={
                  <Fragment>
                    <Form.Field>
                      <label className="label">
                        Name
                      </label>
                      <Form.Control iconLeft>
                        <Form.Input
                          className="input"
                          type="text"
                          placeholder="John Doe"
                          name="Name"
                          value={state.Name}
                          onChange={onChange} />
                        <Icon
                          size="small"
                          align="left">
                          <FontAwesomeIcon icon={faUser} />
                        </Icon>
                      </Form.Control>
                    </Form.Field>
                    <Form.Field>
                      <label className="label">
                        Email
                      </label>
                      <Form.Control iconLeft>
                        <Form.Input
                          className="input"
                          type="email"
                          placeholder="john@doe.com"
                          name="Email"
                          value={state.Email}
                          onChange={onChange} />
                        <Icon
                          size="small"
                          align="left">
                          <FontAwesomeIcon icon={faEnvelope} />
                        </Icon>
                      </Form.Control>
                    </Form.Field>
                    <Form.Field>
                      <label className="label">
                        Message
                      </label>
                      <Form.Control>
                        <textarea
                          className="textarea"
                          placeholder="Your message here..."
                          name="Message"
                          value={state.Message}
                          onChange={onChange} />
                      </Form.Control>
                    </Form.Field>
                  </Fragment>
                }
                submit= {
                  <Form.Field>
                    <Form.Control>
                      <Button
                        submit
                        className="submit-button">
                          Submit&nbsp;&nbsp;
                          <FontAwesomeIcon icon={faPaperPlane} />
                      </Button>
                    </Form.Control>
                  </Form.Field>
                }
                success={
                  <Form.Field>
                    <Message color="info">
                      <Message.Header>
                        Message Sent
                        <Button type="button" remove onClick={onClose} />
                      </Message.Header>
                      <Message.Body>
                        Thanks for contacting us!
                      </Message.Body>
                    </Message>
                  </Form.Field>
                }
                error={
                  <Form.Field>
                    <Message color="danger">
                      <Message.Header>
                        Message Failed to Send
                        <Button type="button" remove onClick={onClose} />
                      </Message.Header>
                      <Message.Body>
                        {
                          !!state.invalidMessage ? state.invalidMessage : "Sorry about that"
                        }
                      </Message.Body>
                    </Message>
                  </Form.Field>
                } />
            <form
              action="https://formspree.io/xdogydwp"
              method="POST">
            </form>
          </Columns.Column>
        </Columns>
      </Container>
    </StyledSection>
  );
}