import React from 'react';
import { Container, Heading, Columns, Content } from 'react-bulma-components';

import { StyledSection } from './styledSection'

import "../style/about.scss"

export const About = ({style='light'}) => (
	<StyledSection id="about" style={style}>
		<Container>
			<Columns
				multiline>
				<Columns.Column
					className="has-vertically-aligned-content"
					size={8}
					offset={2}>
					<Heading className="section-title has-text-centered">
						About
					</Heading>
					<Content renderAs="p" className="no-margin">
						Photo Roboto allows you take photos and videos and upload them instantly to the cloud. 
						No more finding and uploading your media at a separate time, do it directly and immediately.
					</Content>
					<br />
					<Heading subtitle renderAs="h2" className="centerable">
						Features:
					</Heading>
					<Content renderAs="p" className="no-margin mobileSpace">
						⦿ Connect any existing Google Drive account
					</Content>
					<Content renderAs="p" className="no-margin mobileSpace">
						⦿ Upload your photos and videos to the cloud instead of using up your device's storage
					</Content>
					<Content renderAs="p" className="no-margin mobileSpace">
						⦿ Choose or create folders to upload your new and existing photos too
					</Content>
					<Content renderAs="p" className="no-margin mobileSpace">
						⦿ Import and upload photos and videos from your device's Camera Roll
					</Content>
					<Content renderAs="p" className="no-margin mobileSpace">
						⦿ Choose the image format of your uploads. Either PNG, JPG, or PDF
					</Content>
					<Content renderAs="p">
						⦿ Automatically detect, scan, crop, and upload documents to the cloud
					</Content>
				</Columns.Column>
			</Columns>
		</Container>
	</StyledSection>
);
