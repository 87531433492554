import React from 'react';

import { Button, Heading, Columns } from 'react-bulma-components';

import { IPhone5s } from "../components/devices"

import "../style/devices.css"

const Main = () => (
	<div className="bodyBackgroundContainer">
		<Columns>
			<Columns.Column
				style={{alignItems: "flex-start"}}>
				<Heading size={2}>
					Instantly connect your camera to the Cloud
				</Heading>
				<Heading size={5} subtitle>
					Upload photos, videos, and scans directly to the cloud
				</Heading>
				<Columns>
					<Columns.Column>
						<a href="#download">
							<Button
								rounded
								className="heroButton">
								Download the app
							</Button>
						</a>
						</Columns.Column>
						<Columns.Column>
						<a href="#signup">
							<Button
								rounded
								className="heroButton">
								Join the Beta
							</Button>
						</a>
					</Columns.Column>
				</Columns>
			</Columns.Column>
			<Columns.Column>
				<IPhone5s
					size="large"
					image={require("../images/photo_roboto_screenshot_1.png")} />
			</Columns.Column>
		</Columns>
	</div>
);

export default Main;
