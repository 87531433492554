import React, { Fragment, useRef, useState } from 'react';
import { Button, Container, Heading, Columns, Form, Icon, Message } from 'react-bulma-components';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faPaperPlane } from '@fortawesome/free-solid-svg-icons';

import { ContactForm } from './contactForm'
import { StyledSection } from './styledSection'

import "../style/signup.scss"

export const Signup = ({style='light'}) => {
	const [state, setState] = useState({
	  Email: '',
	  Message: "Please send me an invite to join the Photo Roboto Beta",
	  messageClosed: false,
	  invalidMessage: ""
	});
  
	const child = useRef(null)
  
	const onChange = (evt) => {
	  const value = evt.target.type === 'checkbox' ? evt.target.checked : evt.target.value;
	  setState({...state, [evt.target.name]: value});
	}
  
	const onClose = () => {
	  setState({...state, messageClosed: true, invalidMessage: ""});
	  child.current.resetForceStatus();
	}

	return (
		<StyledSection id="signup" style={style}>
			<Container>
				<Columns.Column
					className="about-me"
					size={12}>
					<Heading className="section-title has-text-centered">
						Join our Beta!
					</Heading>
					<ContactForm
						ref={child}
						wrapFields
						action="https://formspree.io/mdodjbav"
						emptyStatus={state.messageClosed}
						submitCallback={() => setState({...state, messageClosed: false})}
						isInvalid={() => {
							if (!state.Email){
								setState({...state, invalidMessage: "Email cannot be empty"})
								return true
							}

							return false
						}}
						fields={
							<Fragment>
								<Form.Control iconLeft>
									<Form.Input
										className="input"
										type="email"
										placeholder="john@doe.com"
										name="Email"
										value={state.Email}
										onChange={onChange} />
										<Icon
											size="small"
											align="left">
											<FontAwesomeIcon icon={faEnvelope} />
										</Icon>
								</Form.Control>
								<Form.Control style={{display: "none"}}>
									<Form.Input
										type="text"
										name="Message"
										value={state.Message}/>
								</Form.Control>
							</Fragment>
						}
						submit= {
							<Form.Control>
								<Button
									submit
									className="submit-button">
									Submit&nbsp;&nbsp;
									<FontAwesomeIcon icon={faPaperPlane} />
								</Button>
							</Form.Control>
						}
						success={
							<Form.Field className="message">
								<Message color="info">
									<Message.Header>
										Beta Request Sent Successfully
										<Button type="button" remove onClick={onClose} />
									</Message.Header>
									<Message.Body>
										Thanks for your interest in the Beta!
										We will respond as soon as possible
									</Message.Body>
								</Message>
							</Form.Field>
						}
						error={
							<Form.Field className="message">
								<Message color="danger">
									<Message.Header>
										Failed to Signup for Beta
										<Button type="button" remove onClick={onClose} />
									</Message.Header>
									<Message.Body>
										{
											!!state.invalidMessage ? state.invalidMessage : "Sorry about that"
										}
									</Message.Body>
								</Message>
							</Form.Field>
						} />
				</Columns.Column>
			</Container>
		</StyledSection>
	);
};
