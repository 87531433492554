import React from "react"

import { About } from "../components/about"
import { Contact } from "../components/contact"
import Hero from "../components/hero"
import Layout from "../components/layout"
import { NavBar } from "../components/navbar"
import { Signup } from "../components/signup"

const IndexPage = () => (
    <Layout>
        <div className="header-wrapper" id="home">
            <NavBar />
            <Hero />
        </div>
        <div className="main-content">
            <About style="color" />
            <Signup style="dark" />
            <Contact />
        </div>
    </Layout>
)

export default IndexPage
